import React from 'react'

export default function Photos() {
    return (
        <div>
            <div className="container">

                <h1 className="fw-light text-center text-lg-start mt-4 mb-0">Thumbnail Gallery</h1>

                <hr className="mt-2 mb-5"></hr>

                <div className="row text-center text-lg-start">

                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/Nibbāna Bodhi (Front Side) Vol1.jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/Nibbāna Bodhi (Front Side) Vol2.jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/Nibbāna Bodhi (Front Side) Vol3.jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/Nibbāna Bodhi (Front Side) Vol4.jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/Nibbāna Bodhi (Front Side) Vol5.jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/Nibbāna Bodhi (Front Side) Vol6.jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/Nibbāna Bodhi (Front Side) Vol7.jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/Nibbāna Bodhi (Front Side) Vol8.jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/Nibbāna Bodhi (Front Side) Vol801.jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/Nibbāna Bodhi (Front Side) Vol9.jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/Nibbāna Bodhi (Front Side) Vol10.jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/Nibbāna Bodhi (Front Side) Vol11.jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/Nibbāna Bodhi (Front Side) Vol12.jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/kushinagar (3).jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/kushinagar (1).jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    
                    
                    
                    
                    
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/kushinagar (8).jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/kushinagar (9).jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/kushinagar (10).jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/kushinagar (11).jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/kushinagar (12).jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/kushinagar (5).jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/kushinagar (2).jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/kushinagar (13).jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/kushinagar (6).jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/kushinagar (7).jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6">
                        <div className="d-block mb-4 h-100">
                            <img className="img-fluid img-thumbnail" src="./images/kushinagar (4).jpg" alt="Nibbana Bodhi"></img>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
