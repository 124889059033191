import React from 'react'
import Body from './homepage/Body'

function Index() {
    return (
        <div>
           <Body />
        </div>
    )
}

export default Index
